<template>
  <div class="header">
    <!-- <div class="ip-line">
      <div>IP address: 150.109.114.218</div>
      <div>ISP: Tencent cloud computing</div>
      <div>Your status:<span class="ip-status"> Unprotected</span></div>
    </div> -->
    <div class="header-content">
      <router-link :to="`${'/' + this.GLOBAL.currentLanguage + '/'}`">
        <img alt="shell vpn" src="../assets/image/logo.svg" class="logo" />
      </router-link>
      <div class="menu_login">
        <div class="menu_list menu_list_hide">
          <router-link
            :to="`${'/' + this.GLOBAL.currentLanguage + '/pricing'}`"
            :class="{ currentSelectedMenu: currentSelectMenuIndex === 1 }"
            style="margin-left: 0"
          >
            {{ $t("header_pricing") }}
          </router-link>
          <router-link
            :to="`${'/' + this.GLOBAL.currentLanguage + '/apps?platform=0'}`"
            :class="{ currentSelectedMenu: currentSelectMenuIndex === 2 }"
          >
            <div @mouseover="showOrHidePlatform(1)">
              {{ $t("header_apps") }}
              <img
                src="../assets/icons/expand_more.svg"
                class="app-list-down"
                :style="{
                  transform: isShowAppPlatform
                    ? 'rotate(-180deg)'
                    : 'rotate(0deg)',
                }"
              />
            </div>
          </router-link>
          <a
            href="https://shellvpn.tawk.help/"
            >{{ $t("header_support") }}</a
          >
          <a
            href="https://blog.surfshell.io/"
            >{{ $t("header_blog") }}
          </a>
        </div>
        <div class="login_and_get">
          <div class="login-content" v-show="!this.checkAuth()">
            <router-link
              :to="`${'/' + this.GLOBAL.currentLanguage + '/login'}`"
            >
              <img
                alt="account"
                src="../assets/image/user_terminal.svg"
                class="user-terminal-icon"
              />
              <span>{{ $t("header_sign") }}</span>
            </router-link>
          </div>
          <div class="login-content" v-show="this.checkAuth()">
            <router-link
              :to="`${'/' + this.GLOBAL.currentLanguage + '/account'}`"
            >
              <span>My Account</span>
            </router-link>
          </div>
          <router-link
            :to="`${'/' + this.GLOBAL.currentLanguage + '/pricing'}`"
          >
            <button
              type="button"
              class="header-btn btn-radius-format-1 btn-color-1 btn-cursor"
            >
              <span>{{ $t("header_get_started") }}</span>
            </button>
          </router-link>
        </div>
      </div>
      <img
        class="small_menu_open btn-cursor"
        src="../assets/image/small_menu_open.svg"
        @click="this.isShowSmallMenu = true"
      />
      <SmallMenu
        :show="this.isShowSmallMenu"
        @onClose="() => this.isShowSmallMenu = false"
      />
    </div>
    <div
      class="apps-row"
      v-show="isShowAppPlatform"
      @mouseleave="showOrHidePlatform(0)"
    >
      <div class="apps-box">
        <div class="apps-platform">
          <div class="apps-device-title">Desktop & Laptops</div>
          <div class="apps-platform-box">
            <router-link
              :to="`${'/' + this.GLOBAL.currentLanguage + '/apps?platform=1'}`"
            >
              <div class="platform apps-platform-box-space">
                <img
                  src="../assets/icons/header-windows.svg"
                  class="apps-platform-icon"
                />
                <span>Windows</span>
              </div>
            </router-link>
            <router-link
              :to="`${'/' + this.GLOBAL.currentLanguage + '/apps?platform=2'}`"
            >
              <div class="platform">
                <img
                  src="../assets/icons/header-mac.svg"
                  class="apps-platform-icon"
                />
                <span>Mac</span>
              </div>
            </router-link>
          </div>
        </div>
        <div class="vertical-line"></div>
        <div class="apps-platform">
          <div class="apps-device-title">Phones & Tablets</div>
          <div class="apps-platform-box">
            <router-link
              :to="`${'/' + this.GLOBAL.currentLanguage + '/apps?platform=0'}`"
            >
              <div class="platform apps-platform-box-space">
                <img
                  src="../assets/icons/header-android.svg"
                  class="apps-platform-icon"
                />
                <span>Android</span>
              </div>
            </router-link>
            <div
              class="platform"
              @mouseleave="showOrHideDownloadIosTip(0)"
              @mouseover="showOrHideDownloadIosTip(1)"
            >
              <img
                src="../assets/icons/header-ios.svg"
                class="apps-platform-icon"
              />
              <span>iOS</span>
              <div class="download-ios-tip" v-show="isShowDownloadIosTip">
                <span>coming soon...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmallMenu from "../components/SmallMenu.vue";

export default {
  name: "Header",
  components: { SmallMenu },
  data() {
    return {
      currentSelectMenuIndex: 0,
      isShowAppPlatform: false,
      isShowDownloadIosTip: false,
      isShowSmallMenu: false,
    };
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    this.getCurrentMenuIndex();
    let headerElement = document.getElementsByClassName("header")[0];
    let box = headerElement.parentElement;
    box.style.paddingTop = Number(headerElement.offsetHeight) + "px";
  },
  methods: {
    showOrHidePlatform(value) {
      this.isShowAppPlatform = !!value;
    },
    showOrHideDownloadIosTip(value) {
      this.isShowDownloadIosTip = !!value;
    },
    getCurrentMenuIndex() {
      const path = this.$route.path.replace(
        "/" + this.GLOBAL.currentLanguage,
        ""
      );
      switch (path) {
        case "/index":
        case "/":
          this.currentSelectMenuIndex = 0;
          break;
        case "/pricing":
          this.currentSelectMenuIndex = 1;
          break;
        case "/apps":
          this.currentSelectMenuIndex = 2;
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  background: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ip-line {
  width: 100%;
  background: #000000;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  color: #b4b4b4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ip-line div:nth-child(1) {
  margin-right: 65px;
}

.ip-line div:nth-child(2) {
  margin-right: 45px;
}

.ip-status {
  color: #ff0000;
}

.header-content {
  width: 100%;
  max-width: 1760px;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu_login {
  margin-right: 111px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1100px) {
  .menu_login {
    display: none;
  }
}

.logo {
  margin-left: 114px;
}

@media screen and (max-width: 1100px) {
  .logo {
    margin-left: 15px;
  }
}

.menu_list {
  line-height: 18px;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  display: flex;
}

@media screen and (max-width: 1100px) {
  .menu_list_hide {
    display: none;
  }
}

.menu_list a {
  margin-right: 55px;
}

.menu_list a:hover {
  color: #4ebe86;
}

.app-list-down {
  width: 7px;
  margin-left: 4px;
  transition: all 0.3s;
}

.user-terminal-icon {
  width: 18px;
  height: 18px;
}

.login_and_get {
  margin-left: 55px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .login_and_get {
    margin-left: 0;
  }
}

.login_and_get a {
  display: flex;
  align-items: center;
}

.login-content span {
  margin-left: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}

.header-btn {
  margin-left: 20px;
  width: 120px;
  height: 36px;
}

@media screen and (max-width: 1100px) {
  .header-btn {
    display: none;
  }
}

.header-btn span {
  font-size: 14px;
}

.currentSelectedMenu {
  color: #4ebe86;
}

.apps-row {
  width: 100%;
  padding: 35px 0;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: black;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.apps-box {
  display: flex;
}

.apps-device-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #ffffff;
}

.apps-platform-box {
  width: 234px;
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
}

.platform {
  width: 83px;
  border-radius: 8px;
  text-align: center;
  position: relative;
}

.platform:hover {
  background: #4b4b4b;
}

.apps-platform-box-space {
}

.apps-platform-icon {
  width: 38px;
  height: 33px;
  margin-top: 25px;
}

.apps-platform-box span {
  font-size: 14px;
  font-weight: 400;
  line-height: 36px;
  color: #ffffff;
  margin-top: 9px;
  display: block;
}

.vertical-line {
  width: 39px;
  height: 80px;
  border-right: 1px solid #ffffff;
  margin-top: 38px;
  margin-right: 73px;
}

.download-ios-tip {
  width: 88px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 22px;
  left: 110%;
  color: #1d1d1d;
}

.download-ios-tip span {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #2d3b3d;
  margin: 0;
  text-align: center;
}

/* small menu will appear when the width is less than 1100px */
.small_menu_open {
  width: 22px;
  height: 22px;
  margin-right: 15px;
}
@media screen and (min-width: 1100px) {
  .small_menu_open {
    display: none;
  }
}
</style>
