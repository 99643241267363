<template>
  <div class="footer">
    <div class="footer-box">
      <div class="footer-menu">
        <div class="footer-company-info">
          <router-link :to="`${'/' + this.GLOBAL.currentLanguage + '/'}`">
            <img alt="" src="../assets/image/logo.svg" class="footer-logo" />
          </router-link>
          <div class="footer-company-address">
            ShellVPN<br />
            99 Wall St Ste 4949<br />
            New York, NY
          </div>
          <div class="social">
            <img
              class="social-platform"
              :src="twitterIconName"
              v-on:click="clickHref('twitter')"
              @mouseover="overOrLeaveIcon('twitter', true)"
              @mouseleave="overOrLeaveIcon('twitter', false)"
            />
            <img
              class="social-platform"
              :src="facebookIconName"
              v-on:click="clickHref('facebook')"
              @mouseover="overOrLeaveIcon('facebook', true)"
              @mouseleave="overOrLeaveIcon('facebook', false)"
            />
          </div>
        </div>

        <div
          v-for="(menuCol, menuIndex) in footerMenuList"
          :key="menuCol.title"
          :class="`${'footer-menu-col-space'} ${
            'footer-menu-col-' + menuIndex
          }`"
        >
          <div class="footer-menu-title">{{ $t(menuCol.title) }}</div>
          <ul>
            <li
              v-for="(subMenu, subMenuIndex) in menuCol.subMenuList"
              :key="subMenu.title"
              class="footer-sub-menu-title"
              :class="{ 'footer-sub-menu-space': subMenuIndex !== 0 }"
            >
              <router-link
                :to="`${'/' + GLOBAL.currentLanguage + subMenu.href}`"
                v-if="subMenu.href"
              >
                {{ $t(subMenu.title) }}
              </router-link>
              <a :href="subMenu.link" v-if="subMenu.link">{{
                $t(subMenu.title)
              }}</a>
              <div
                class="click-btn"
                v-on:click="clickHandler(subMenu.click)"
                v-if="subMenu.click"
              >
                {{ $t(subMenu.title) }}
              </div>
            </li>
          </ul>
        </div>
        <div
          class="footer-language"
          @mouseleave="showOrHideLanguageList(false)"
        >
          <div class="footer-language-title">Language</div>
          <div
            class="footer-language-content"
            @mouseover="showOrHideLanguageList(true)"
            :style="{
              color: isShowAllLanguage === true ? '#4EBE86' : '#3A4D50',
            }"
          >
            <div>
              {{
                this.GLOBAL.supportLanguage[
                  this.GLOBAL.formatLocale(this.GLOBAL.currentLanguage)
                ]
              }}
            </div>
            <img
              src="../assets/icons/expand_more.svg"
              class="footer-language-expand-more"
              :style="{
                transform:
                  isShowAllLanguage === true
                    ? 'rotate(-180deg)'
                    : 'rotate(0deg)',
              }"
            />
          </div>
          <ul class="footer-language-list" v-show="isShowAllLanguage">
            <li
              class="footer-language-row"
              @click="changeCurrentLanguage(key)"
              v-for="(language, key) in GLOBAL.supportLanguage"
              :key="key"
              :class="{
                'footer-language-current-language-row':
                  key === GLOBAL.formatLocale(GLOBAL.currentLanguage),
              }"
            >
              <span>{{ language }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer-tip-space" v-if="isAcceptPrivacyPolicy !== 1"></div>
    <div class="footer-tip-box" v-if="isAcceptPrivacyPolicy !== 1">
      <div class="footer-tip">
        By browsing this website, you consent to our -
        <router-link
          :to="`${'/' + this.GLOBAL.currentLanguage + '/privacy_policy'}`"
          ><span class="text-bold">Privacy Policy</span>
        </router-link>
      </div>
      <button class="footer-tip-btn btn-cursor" @click="acceptPrivacyPolicy">
        <span>Accept</span>
      </button>
    </div>
    <div class="footer-copyright">
      © {{ currentYear }} Copyright ShellVPN. All rights reserved.
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      isAcceptPrivacyPolicy: 0,
      footerMenuList: [
        {
          title: "footer_vpn_apps",
          subMenuList: [
            {
              title: "footer_vpn_for_android",
              href: "/apps?platform=0",
            },
            {
              title: "footer_vpn_for_windows",
              href: "/apps?platform=1",
            },
            {
              title: "footer_vpn_for_macos",
              href: "/apps?platform=2",
            },
            {
              title: "footer_vpn_for_ios",
              href: "/apps",
            },
          ],
        },
        {
          title: "footer_products",
          subMenuList: [
            {
              title: "footer_pricing",
              href: "/pricing",
            },
            {
              title: "footer_features",
              href: "/index?anchor=features_anchor",
            },
            {
              title: "footer_servers",
              href: "/index?anchor=server_anchor",
            },
          ],
        },
        {
          title: "footer_support",
          subMenuList: [
            {
              title: "footer_help_center",
              link: "https://shellvpn.tawk.help",
            },
            {
              title: "footer_contact_us",
              click: "startContactUs",
            },
          ],
        },
        {
          title: "footer_programs",
          subMenuList: [
            {
              title: "footer_refer_a_friend",
              href: "/login",
            },
          ],
        },
        {
          title: "footer_company",
          subMenuList: [
            {
              title: "footer_blog",
              link: "https://blog.surfshell.io/",
            },
            {
              title: "footer_terms_of_service",
              href: "/term",
            },
            {
              title: "footer_privacy_policy",
              href: "/privacy_policy",
            },
          ],
        },
      ],
      isShowAllLanguage: false,
      currentYear: 2021,
      facebookIconName: require("../assets/icons/facebook.svg"),
      twitterIconName: require("../assets/icons/twitter.svg"),
    };
  },
  methods: {
    overOrLeaveIcon(platform, value) {
      switch (platform) {
        case "facebook":
          this.facebookIconName = value
            ? require("../assets/icons/footer-facebook_mouseover.svg")
            : require("../assets/icons/facebook.svg");
          break;
        case "twitter":
          this.twitterIconName = value
            ? require("../assets/icons/footer-twitter_mouseover.svg")
            : require("../assets/icons/twitter.svg");
          break;
        default:
          break;
      }
    },
    changeCurrentLanguage(language) {
      this.isShowAllLanguage = false;
      this.$storage.set("currentLanguage", language);
      window.location.href = window.location.href.replace(
        new RegExp(`/${this.GLOBAL.currentLanguage}/`),
        "/" + language + "/"
      );
    },
    showOrHideLanguageList(value) {
      this.isShowAllLanguage = value;
    },

    acceptPrivacyPolicy() {
      this.isAcceptPrivacyPolicy = 1;
      this.$storage.set("acceptPrivacyPolicy", 1);
    },
    startContactUs() {
      // eslint-disable-next-line no-undef
      Tawk_API.maximize();
    },
    clickHandler(clickEvent) {
      this[clickEvent]();
    },
    clickHref(platform) {
      console.log(process.env);
      switch (platform) {
        case "facebook":
          window.open(process.env.VUE_APP_FACEBOOK);
          break;
        case "twitter":
          window.open(process.env.VUE_APP_TWITTER);
          break;
        default:
          break;
      }
    },
  },
  created() {
    this.isAcceptPrivacyPolicy = parseInt(
      this.$storage.get("acceptPrivacyPolicy")
    );
    this.currentYear = new Date().getFullYear();
  },
};
</script>

<style scoped>
.footer {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.footer-box {
  display: flex;
  justify-content: center;
  padding: 81px 16px 31px;
  flex-wrap: wrap;
}

.footer-company-info {
  display: flex;
  flex-direction: column;
  margin: 30px 25px;
}

.footer-logo {
  margin-top: 5px;
}

.footer-company-address {
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  color: #3a4d50;
  margin-top: 20px;
  margin-right: 50px;
}

.social {
  margin-top: 20px;
}

.social-platform {
  margin-right: 20px;
}

.footer-menu {
  display: flex;
  flex-wrap: wrap;
}

.footer-menu-col-space {
  margin: 30px 25px;
}

.footer-menu-title {
  font-size: 14px;
  font-weight: 500;
  color: #3a4d50;
  height: 18px;
  margin-bottom: 40px;
}

.footer-sub-menu-title {
  line-height: 18px;
  font-size: 14px;
  font-weight: 300;
  color: #3a4d50;
}

.footer-sub-menu-space {
  margin-top: 26px;
}

/*In order to be consistent with the width of UI diagram*/
.footer-menu-col-0 {
  max-width: 141px;
}

.footer-menu-col-1 {
  max-width: 71px;
}

.footer-menu-col-2 {
  max-width: 93px;
}

.footer-menu-col-3 {
  max-width: 107px;
}

.footer-menu-col-4 {
  max-width: 131px;
}

.footer-language {
  min-width: 90px;
  max-width: 141px;
  margin: 30px 23px;
}

.footer-language-title {
  height: 18px;
  font-size: 14px;
  font-weight: 500;
  color: #3a4d50;
}

.footer-language-content {
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: #3a4d50;
  margin-top: 15px;
}

.footer-language-expand-more {
  width: 9px;
  margin-left: 10px;
  transition: all 0.3s;
}

.footer-language-list {
  max-height: 146px;
  border-radius: 4px;
  border: 1px solid #f5f5f5;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  overflow: auto;
  margin-top: 8px;
  margin-left: -10px;
}

.footer-language-row {
  width: initial;
  box-sizing: border-box;
  height: 30px;
  padding-left: 8px;
  line-height: 30px;
}

.footer-language-row:hover {
  background-color: rgba(78, 190, 134, 0.1);
}

.footer-language-row span {
  font-size: 14px;
  font-weight: 400;
  color: #3a4d50;
}

.footer-language-current-language-row {
  background-color: rgba(78, 190, 134, 1);
}

.footer-sub-menu-title a:hover {
  color: #4ebe86;
}

.click-btn {
  cursor: pointer;
}

.footer-copyright {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
  background-color: #fafafa;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #666666;
  text-align: center;
}

.footer-tip-space {
  height: 70px;
}

.footer-tip-box {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  position: fixed;
  /* TODO If add this style, on mobile devices, it doesn't in the center... */
  /* margin: 0 16px; */
  padding: 10px 0;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  bottom: 51px;
  z-index: 999;
  flex-wrap: wrap;
}

.footer-tip {
  margin: 10px 56px;
  font-size: 16px;
  font-weight: 400;
  color: #3a4d50;
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.footer-tip-btn {
  margin: 10px 56px;
  width: 125px;
  height: 38px;
  background: #00783c;
  border-radius: 10px;
  line-height: 38px;
}

.footer-tip-btn:hover {
  background: #1ba761;
}

.footer-tip-btn span {
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
}
</style>
